import { create } from 'zustand';

interface CounterState {
  counterSubscribe: number;
  setCounterSubscribe: (value: number) => void;
}

export const useCounterSubscribe = create<CounterState>((set) => ({
  counterSubscribe: 0,
  setCounterSubscribe: (value: number) => set({ counterSubscribe: value }),
}));
